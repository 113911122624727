<template>
  <div style="height: 100%;">
    <van-image fit="cover" :src="src" />
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant'
export default {
  data () {
    return {
      show: false,
      src: require('@/assets/img/redPacketRain/redRainbg.png')
    }
  },

  mounted () {
    Toast.setDefaultOptions({ duration: 10000 })
    this.showToast()
  },
  
  methods: {
    download () {
      let id = setTimeout(() => {
        if (navigator.userAgent.includes("Mac")) {
          window.location.href = "https://apps.apple.com/cn/app/id1638693950";
        }
        if (!navigator.userAgent.includes("Mac")) {
          this.alertShow = false;
          this.$dialog
            .confirm({
              title: "提示",
              message: "即将下载Win生活安装包",
            })
            .then(() => {
              window.location.href = "https://www.pgyer.com/eOTh"
            })
            .catch(() => {
              this.alertShow = true
            })
        }
      }, 500)

      document.addEventListener("visibilitychange", () => {
        if (document.hidden || document.webkitHidden) clearInterval(id);
      })
      document.addEventListener("qbrowserVisibilityChange", (e) => {
        if (e.hidden || document.webkitHidden) {
          clearInterval(id)
        }
      })
    },

    enterApp () {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      if (isIOS) {
        window.location.href = 'winlife://openWin'
      }
      if (isAndroid) {
        window.location.href = 'winlife://openWin'
        // var iFrame
        // iFrame = document.createElement("iframe")
        // iFrame.setAttribute("src", 'ydmall://main/main')
        // iFrame.setAttribute("style", "display:none;")
        // iFrame.setAttribute("height", "0px")
        // iFrame.setAttribute("width", "0px")
        // iFrame.setAttribute("frameborder", "0")
        // document.body.appendChild(iFrame)
        // iFrame.parentNode.removeChild(iFrame)
        // iFrame = null
      }
    },

    showToast () {
      if (navigator.userAgent.indexOf('QQTheme') != -1 || navigator.userAgent.indexOf('MicroMessenger') != -1) {
        Toast.fail('苹果设备请在默认浏览器打开，安卓设备请复制链接到360浏览器或UC浏览器打开')
      } else {
        this.Dialog()
      }
    },

    Dialog () {
      Dialog.confirm({
        title: '红包雨活动',
        cancelButtonText: '去下载',
        confirmButtonText: '打开app'
      }).then(() => {
        this.enterApp()
      }).catch(() => {
        this.download()
      })
    }
  }
}
</script>

<style lang="less" scoped>
.van-image {
  width: 100%;
  height: 100%;
}
.dialog {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  &-item {
    width: 92%;
    background: #fff;
    height: 80px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5);
    padding: 10px 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &-div {
      display: flex;
      justify-content: space-between;
      align-content: center;
      &-span {
        color: #2E71FE;
      }
      &-span:active {
        color: rgba(46, 113, 254, 0.5);
      }
    }
  }
}
</style>